import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import LinkButton from '../LinkButton';

const PLACES = {
    PASSWORD_CHANGE_SCREEN: 'PASSWORD_CHANGE_SCREEN',
    CHECK_INBOX_SUCCESS_SCREEN: 'CHECK_INBOX_SUCCESS_SCREEN',
    CHECK_INBOX_ERROR_SCREEN: 'CHECK_INBOX_ERROR_SCREEN',
    WHY_TO_CHANGE_PASSWORD: 'WHY_TO_CHANGE_PASSWORD',
    NO_CONFIRMATION_FROM_EMAIL: 'NO_CONFIRMATION_FROM_EMAIL',
} as const;

const LINKS = {
    [PLACES.PASSWORD_CHANGE_SCREEN]:
        'https://help.transportexchangegroup.com/hc/en-gb/articles/6805389262492#h_01GVDVSDFSQJSYZ7CGBJQGW8JX',
    [PLACES.CHECK_INBOX_SUCCESS_SCREEN]:
        'https://help.transportexchangegroup.com/hc/en-gb/articles/6805389262492#h_01GVDVV3765SET3VZ3P1QN5DM4',
    [PLACES.CHECK_INBOX_ERROR_SCREEN]:
        'https://help.transportexchangegroup.com/hc/en-gb/articles/6805389262492#h_01GVDVV3765SET3VZ3P1QN5DM4',
    [PLACES.NO_CONFIRMATION_FROM_EMAIL]:
        'https://help.transportexchangegroup.com/hc/en-gb/articles/6805389262492#h_01GVDW0TS5JJR58614DP774QYG',
    [PLACES.WHY_TO_CHANGE_PASSWORD]:
        'https://help.transportexchangegroup.com/hc/en-gb/articles/7364393591836-We-re-asking-you-tochangePassword-update-your-Exchange-password-for-2023-Here-s-why-',
} as const;

const WhyLink: React.FC<{ place: keyof typeof PLACES }> = ({ place }) => {
    const { t } = useTranslation();

    return (
        <LinkButton
            className={styles.link}
            href={LINKS[place]}
            rel='noopener noreferrer'
            target='_blank'
            test-id='why-link'
            da-id='why-link'
        >
            {t('Why?')}
        </LinkButton>
    );
};

export default WhyLink;
