import React from 'react';
import cn from 'classnames';
import Spinner from './Spinner';

import { OptionalClassName } from '../../types/OptionalClassName';
import styles from './styles.module.scss';

type LoaderProps = {
    center?: boolean;
    stretch?: boolean;
    style?: { [key: string]: unknown };
} & OptionalClassName;

const Loader: React.FC<LoaderProps> = ({ style, center, stretch, className }) => {
    return (
        <span
            className={cn(
                {
                    [styles.center]: center,
                    [styles.stretch]: stretch,
                },
                className,
            )}
            style={style}
        >
            <Spinner className={styles.spinner} />
        </span>
    );
};

export default Loader;
