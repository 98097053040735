import React, { useState } from 'react';
import { FormikValidationResult } from 'types';
import { collectError, isString, trimValue } from 'utils';

import { forgotPasswordToCXAuthRequest } from '../../../../api/cx';
import { forgotPasswordToFXRequest } from '../../../../api/fx';
import { hasErrors } from '../../../../common/utils/errors';
import ForgotPasswordForm, { fieldNames, validate } from '../../components/ForgotPasswordForm';

const ForgotPasswordFormContainer: React.FC = () => {
    const [formError, setFormError] = useState<string | undefined>();
    const [isSubmitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState<Record<string, FormikValidationResult>>({});
    const [passwordHadBeenSent, setPasswordHadBeenSent] = useState(false);

    async function onSubmit(event: React.FormEvent<HTMLFormElement>): Promise<void> {
        event.preventDefault();

        if (hasErrors(errors)) {
            return;
        }

        const form = event.target as HTMLFormElement;
        const value = trimValue(form[fieldNames.login].value);
        const validationResult = validate(value);

        if (validationResult) {
            return setErrors({ [fieldNames.login]: validationResult });
        }

        try {
            setSubmitting(true);
            let errorMessage = '';
            const { beta, message } = await forgotPasswordToCXAuthRequest(value);

            if (message) {
                errorMessage = message;
            }

            if (beta) {
                const { message } = await forgotPasswordToFXRequest(value);

                if (message) {
                    errorMessage = message;
                }
            }

            if (errorMessage) {
                throw new Error(errorMessage);
            }

            setPasswordHadBeenSent(true);
        } catch (error: any) {
            setFormError(isString(error) ? error : error.message);
            collectError(error);
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <ForgotPasswordForm
            errors={errors}
            setErrors={setErrors}
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
            formError={formError}
            passwordHadBeenSent={passwordHadBeenSent}
        />
    );
};

export default ForgotPasswordFormContainer;
