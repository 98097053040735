import { validateEmailI18n, validateRequiredI18n } from 'utils';

import { CxLoginRequestBody } from '../../../../api/types';
import { fieldNames } from '../../components/LoginForm';
import { hasErrors } from '../../../../common/utils/errors';

const validationRequiredErrorMessage = 'Email and Password are required fields';
export const validationEmailErrorMessage = 'Please enter your verified email address to login';

export const validateLoginForm = (data: CxLoginRequestBody) => {
    const loginError = data.login
        ? !!validateEmailI18n(data.login) && validationEmailErrorMessage
        : validationRequiredErrorMessage;
    const passwordError = !!validateRequiredI18n(data.password) && validationRequiredErrorMessage;

    const errors = {
        [fieldNames.login]: loginError,
        [fieldNames.password]: passwordError,
    };

    return {
        errors,
        isFormReset: errors[fieldNames.login] === validationEmailErrorMessage,
        isValid: !hasErrors(errors),
    };
};
