import React from 'react';
import 'assets/fonts/fonts.css';

import LoginSide from '../../modules/loginSide';
import Greetings from '../../modules/greetings';
import './index.css';
import withMeta from '../../modules/meta/hocs/withMeta';
import withFavicon from '../../modules/meta/hocs/withFavicon';

const App: React.FC = () => {
    return (
        <>
            <Greetings />
            <LoginSide />
        </>
    );
};

export default withFavicon(withMeta(App));
