import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import WhyLink from '../WhyLink';

const NoConfirmationFromEmailMessage = () => {
    const { t } = useTranslation();

    return (
        <div>
            <p className={styles.bold}>{t('You did not confirm your recent password update within 24 hours')}.</p>
            <p>
                <span>{t('Therefore update your password')} </span>
                <span className={styles.bold}>{t('and')} </span>
                <span>{t('confirm the update via your inbox within 24 hours')}. </span>
                <WhyLink place='NO_CONFIRMATION_FROM_EMAIL' />
            </p>
        </div>
    );
};

export default NoConfirmationFromEmailMessage;
