import React, { useState } from 'react';
import { collectError } from 'utils';

import { forceLoginToCXRequest } from '../../../../api/cx';
import { forceLoginToFXRequest } from '../../../../api/fx';
import { LastLogin, Organisation } from '../../../../api/types';
import { loginToOrganisation } from '../../shared/loginToOrganisation';
import { setAppView } from '../../shared/view';
import CurrentSessionView from '../../components/CurrentSessionView';

interface CurrentSessionProps {
    beta: boolean | null;
    lastLogin: NonNullable<LastLogin>;
    organisationsIds: string[];
    setOrganisations: (organisation: Organisation[]) => void;
}

const CurrentSession: React.FC<CurrentSessionProps> = ({ lastLogin, setOrganisations, beta, organisationsIds }) => {
    const [isSubmitting, setSubmitting] = useState(false);

    async function onSubmit(event: React.MouseEvent<HTMLButtonElement>): Promise<void> {
        event.preventDefault();
        setAppView({ submitter: event.currentTarget });

        try {
            setSubmitting(true);

            if (beta) {
                await forceLoginToFXRequest();
                await loginToOrganisation(setOrganisations, organisationsIds);
            } else {
                const { redirectUrl } = await forceLoginToCXRequest();
                window.location.assign(redirectUrl);
            }
        } catch (error: unknown) {
            collectError(error);
        } finally {
            setSubmitting(false);
        }
    }

    return <CurrentSessionView lastLogin={lastLogin} isSubmitting={isSubmitting} onSubmit={onSubmit} />;
};

export default CurrentSession;
