import React, { useState } from 'react';

import Input, { InputProps } from '../Input';
import { ReactComponent as Visibility } from './visibility.svg';
import { ReactComponent as VisibilityOff } from './visibilityOff.svg';

const InputPassword: React.FC<InputProps> = props => {
    const [type, setType] = useState('password');

    const toggleType = () => {
        setType(type === 'password' ? 'text' : 'password');
    };

    const EndAdornment = () => (
        <button onClick={toggleType}>{type === 'password' ? <Visibility /> : <VisibilityOff />}</button>
    );

    return <Input {...props} type={type} endAdornment={<EndAdornment />} />;
};

export default InputPassword;
