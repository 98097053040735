import React from 'react';
import { createRoot } from 'react-dom/client';
import { loadScript, validation, collectError } from 'utils';
import { APP_BUNDLES_PATHS } from 'consts';

import { common } from './modules/loginForm/locales';
import { greetings } from './modules/greetings/locales';
import { i18n } from './core/utils/i18n';
import { loginSide } from './modules/loginSide/locales';
import { messages } from './modules/loginForm/locales';
import Root from './core';

import('assets/fonts/fonts.css');

const projectLocales: Record<string, Record<string, any>> = { en: { common, greetings, loginSide, messages } };
const externalLocales = [validation.locales];

externalLocales.forEach(({ ns, resources }) => {
    Object.entries(resources).forEach(([lang, locales]) => {
        projectLocales[lang][ns] = { ...(projectLocales[lang][ns] || {}), ...locales };
    });
}, projectLocales);

i18n(projectLocales)
    .then(() => {
        console.log(`${process.env.REACT_APP_NAME} commit hash =`, process.env.REACT_APP_COMMIT_HASH); // eslint-disable-line no-console

        const container = document.getElementById('app');
        const root = createRoot(container!);

        root.render(<Root />);
        pendo.initialize({
            disableGuides: true,
        });

        loadScript(APP_BUNDLES_PATHS.MFA);
    })
    .catch(collectError);
