import { FormikValidationResult } from 'types';

export type Validate = (value: any) => FormikValidationResult;

type ValidateComposer = (...args: Validate[]) => Validate;

export const validateCompose: ValidateComposer =
    (f, ...rest) =>
    value =>
        f(value) || (rest.length && validateCompose(...rest)(value)) || undefined;
