import React from 'react';
import { useTranslation } from 'react-i18next';

import SubmitButton from '../SubmitButton';
import styles from './styles.module.scss';

interface PasswordVerifySuccessScreenProps {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const PasswordVerifySuccessScreen: React.FC<PasswordVerifySuccessScreenProps> = ({ onClick }) => {
    const { t } = useTranslation();

    return (
        <div>
            <p className={styles.header}>{t('passwordVerifySuccessTitle')}!</p>
            <p className={styles.message}>{t('passwordVerifySuccessMessage')}.</p>
            <SubmitButton
                da-id='password-verify-success-button'
                onClick={onClick}
                progress={false}
                test-id='password-verify-success-button'
            >
                {t('Continue')}
            </SubmitButton>
        </div>
    );
};

export default PasswordVerifySuccessScreen;
