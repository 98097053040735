import React, { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikValidationResult } from 'types';
import FormError from '../FormError';
import InputPassword from '../InputPassword';
import styles from './styles.module.scss';
import SubmitButton from '../SubmitButton';
import { PasswordError } from '../../containers/NewPasswordContainer/validation';

const preventEvent = (event: any) => {
    event.preventDefault();
};

export const fieldNames = { confirmPassword: 'confirmPassword', newPassword: 'newPassword' } as const;

export type NewPasswordFormErrors = {
    [fieldNames.newPassword]: Array<PasswordError>;
    [fieldNames.confirmPassword]: FormikValidationResult;
};

interface NewPasswordProps {
    errors: NewPasswordFormErrors;
    formError?: string | null;
    isError: boolean;
    isSubmitDisable: boolean;
    isSubmitting: boolean;
    message?: JSX.Element;
    onChange: (event: FormEvent<HTMLFormElement>) => void;
    onSubmit(event: any): Promise<void>;
}

const NewPassword: React.FC<NewPasswordProps> = ({
    errors,
    formError,
    isError,
    isSubmitDisable,
    isSubmitting,
    message,
    onChange,
    onSubmit,
}) => {
    const { t } = useTranslation();

    return (
        <div>
            {message && <p className={styles.row}>{message}</p>}
            <form onSubmit={onSubmit} onChange={onChange} test-id='password-new-form'>
                {formError && <FormError test-id='password-new-form-error'>{formError}</FormError>}
                <InputPassword
                    error={isError}
                    name={fieldNames.newPassword}
                    label={t('New password')}
                    placeholder={t('New password')}
                    test-id='password-new-field-new-password'
                    autoFocus
                />
                <InputPassword
                    error={isError}
                    name={fieldNames.confirmPassword}
                    label={t('Confirm password')}
                    placeholder={t('Confirm password')}
                    test-id='password-new-field-confirm-password'
                    onPaste={preventEvent}
                    onDrop={preventEvent}
                />
                <div className={styles.bold}>{t('Your new password must be')}:</div>
                <ul>
                    {errors.newPassword.map(([text, status]) => (
                        <li className={status ? styles.valid : styles['not-valid']} key={text}>
                            {text}
                        </li>
                    ))}
                </ul>
                <SubmitButton
                    progress={isSubmitting}
                    test-id='password-new-submit'
                    da-id='password-new-submit'
                    disabled={isSubmitDisable}
                >
                    {t('Change password')}
                </SubmitButton>
            </form>
        </div>
    );
};

export default NewPassword;
