import React from 'react';
import styles from './styles.module.scss';

const withBackground = (Component: React.FC): React.FC => () => {
    return (
        <div className={styles.root}>
            <Component />
        </div>
    );
};

export default withBackground;
