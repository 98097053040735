import load from './load';
import hasTag from './hasTag';
var hasScript = hasTag('script');
var loadScript = function loadScript(url) {
  if (hasScript(url)) {
    return Promise.resolve();
  }
  var js = document.createElement('script');
  js.type = 'text/javascript';
  js.src = url;
  return load(js, url);
};
export default loadScript;