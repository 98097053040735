import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Loader from '../../components/Loader';
import { verifyTokenToChangeEmailOnPageLoad } from '../../utils/emails';

type State = {
    error: Awaited<ReturnType<typeof verifyTokenToChangeEmailOnPageLoad>>;
    verified: boolean;
};

const INITIAL_STATE: State = {
    error: undefined,
    verified: false,
};

const ChangeEmail: React.FC = () => {
    const { t } = useTranslation();
    const [state, setState] = useState<State>(INITIAL_STATE);

    useEffect(() => {
        (async () => {
            const error = await verifyTokenToChangeEmailOnPageLoad();

            setState(() => ({ error, verified: true }));
        })();
    }, []);

    if (!state.verified) {
        return <Loader center />;
    }

    return state.error ? (
        <span test-id='change-email-token-error'>{state.error}</span>
    ) : (
        <span test-id='change-email-token-success'>{t('Your email has been changed')}</span>
    );
};

export default ChangeEmail;
