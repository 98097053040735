import { URL_APP_TYPE } from 'consts';

import { CxLoginApiResponse } from '../../api/types';
import { MetaState } from '../../modules/meta/constants';

export const validateAppType = (appType: string | null): appType is MetaState['appType'] => {
    // eslint-disable-next-line
    return Object.values(URL_APP_TYPE).includes(appType as any);
};

export const getAppTypeFromResponseHeaders = (
    response: CxLoginApiResponse,
    urlAppType: MetaState['appType'],
): MetaState['appType'] => {
    const urlApptypeHeader = response.response.headers.get('apptype');

    return validateAppType(urlApptypeHeader) ? urlApptypeHeader : urlAppType;
};
