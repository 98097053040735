import React from 'react';

const Spinner: React.FC<{ className?: string }> = props => {
    return (
        <svg viewBox='0 0 24 24' {...props}>
            <path d='M12 0c.752 0 1.333.744 1.333 1.667V5c0 .923-.615 1.667-1.333 1.667-.752 0-1.333-.744-1.333-1.667V1.667C10.667.744 11.247 0 12 0zm7.806 4.204c.338.337.226 1.035-.271 1.53l-1.806 1.802c-.496.495-1.174.608-1.535.27-.338-.337-.226-1.035.271-1.53l1.806-1.802c.519-.496 1.196-.63 1.535-.27zM24 12c0 .752-.74 1.333-1.66 1.333h-3.347c-.919 0-1.66-.615-1.66-1.333 0-.752.741-1.333 1.66-1.333h3.318c.948 0 1.689.58 1.689 1.333zm-4.204 7.806c-.337.338-1.035.226-1.53-.271l-1.802-1.806c-.495-.496-.608-1.174-.27-1.535.337-.338 1.035-.226 1.53.271l1.802 1.806c.496.519.63 1.196.27 1.535zM12 24c-.752 0-1.333-.74-1.333-1.66v-3.347c0-.919.615-1.66 1.333-1.66.752 0 1.333.741 1.333 1.66v3.318c0 .948-.58 1.689-1.333 1.689zm-7.806-4.204c-.338-.337-.226-1.035.271-1.53l1.806-1.802c.496-.495 1.174-.608 1.535-.27.338.337.226 1.035-.271 1.53l-1.806 1.802c-.519.496-1.196.63-1.535.27zM0 12c0-.752.744-1.333 1.667-1.333H5c.923 0 1.667.615 1.667 1.333 0 .752-.744 1.333-1.667 1.333H1.667C.744 13.333 0 12.753 0 12zm4.204-7.806c.337-.338 1.035-.226 1.53.271l1.802 1.806c.495.496.608 1.174.27 1.535-.337.338-1.035.226-1.53-.271L4.473 5.729c-.496-.519-.63-1.196-.27-1.535z' />
        </svg>
    );
};

export default Spinner;
