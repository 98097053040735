import React from 'react';

import styles from './styles.module.scss';

interface AppLogoViewProps {
    href: string;
    logo: string;
}

const AppLogoView: React.FC<AppLogoViewProps> = ({ href, logo }) => {
    return (
        <a className={styles.login__logo} target='_blank' rel='noopener noreferrer' href={href}>
            <img src={logo} alt='Logo' />
        </a>
    );
};

export default AppLogoView;
