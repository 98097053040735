import { APP_TYPE, URL_APP_TYPE } from 'consts';
import type { ValueOf } from 'types';

import logo_cx from 'assets/logos/logo_cx.svg';
import logo_hx from 'assets/logos/logo_hx.svg';
import logo_fls from 'assets/logos/logo_fls.svg';
import logo_cxna from 'assets/logos/logo_cxna.svg';
import logo_cxeuro from 'assets/logos/logo_cxeuro.svg';
import logo_cp from 'assets/logos/logo_cp.svg';
import logo_carrier from 'assets/logos/logo_carrier.svg';
import logo_broker from 'assets/logos/logo_broker.svg';
import logo_integra from 'assets/logos/logo_integra.svg';
import logo_logiquanta from 'assets/logos/logo_logiquanta.svg';

export const getLogoSrc = (app: ValueOf<typeof URL_APP_TYPE>) => {
    switch (app) {
        case URL_APP_TYPE[APP_TYPE.CX]:
            return logo_cx;
        case URL_APP_TYPE[APP_TYPE.HX]:
        case URL_APP_TYPE[APP_TYPE.SF]:
            return logo_hx;
        case URL_APP_TYPE[APP_TYPE.FLS]:
            return logo_fls;
        case URL_APP_TYPE[APP_TYPE.CXNA]:
        case URL_APP_TYPE[APP_TYPE.CXNA_EXPERDITER]:
            return logo_cxna;
        case URL_APP_TYPE[APP_TYPE.CXEURO]:
            return logo_cxeuro;
        case URL_APP_TYPE[APP_TYPE.CUSTOMER_PORTAL]:
            return logo_cp;
        case URL_APP_TYPE[APP_TYPE.CXNA_CARRIER]:
            return logo_carrier;
        case URL_APP_TYPE[APP_TYPE.CXNA_BROKER]:
            return logo_broker;
        case URL_APP_TYPE[APP_TYPE.INTEGRA]:
        case URL_APP_TYPE[APP_TYPE.LENDER_PORTAL]:
            return logo_integra;
        case URL_APP_TYPE[APP_TYPE.LOGIQUANTA]:
            return logo_logiquanta;
        default:
            return logo_cx;
    }
};
