export const CX_AUTH_ENDPOINT = '/auth/api';
export const FX_AUTH_ENDPOINT = '/member-service/v1';
export const META_ENDPOINT = '/white-label-projection/v1/commands';

export const ORGANIZATION_HEADER_NAME = 'X-ORGANISATION-ID';

export const FORCE_LOGIN_BODY = {
    force: true,
};

export const NOT_FORCE_LOGIN_BODY = {
    force: false,
};

export const HTTP_STATUS = {
    INTERNAL_SERVER_ERROR: 500,
};

export const HTTP_METHOD = {
    GET: 'get',
    POST: 'post',
};

export const EMPTY_OBJECT = {};
