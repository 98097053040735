import * as React from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';
import { Version } from '.';

const CircularProgress: React.FC<{ version?: Version }> = ({ version = 'contained' }) => (
    <div
        className={cn(styles.loader, {
            [styles.loaderContained]: version === 'contained',
            [styles.loaderOutlined]: version === 'outlined',
        })}
    >
        <span></span>
    </div>
);

export default CircularProgress;
