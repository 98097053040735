import React, { PropsWithChildren, useEffect } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';
interface DigicertViewProps {
    spacing: boolean;
}

const INTEGRATION_URL = 'https://seal.digicert.com/seals/cascade/seal.min.js';

const DigicertView: React.FC<PropsWithChildren<DigicertViewProps>> = ({ spacing, children }) => {
    useEffect(() => {
        window.__dcid = window.__dcid || [];
        window.__dcid.push({ cid: process.env.REACT_APP_DIGICERT_ID, tag: process.env.REACT_APP_DIGICERT_TAG });

        const cid = document.createElement('script');
        cid.async = true;
        cid.src = INTEGRATION_URL;
        const s = document.getElementsByTagName('script');
        const ls = s[s.length - 1];
        ls?.parentNode?.insertBefore(cid, ls.nextSibling);
    }, []);

    return (
        <div className={cn(styles.digicert, { [styles.digicert__spacing]: spacing })}>
            <div id={process.env.REACT_APP_DIGICERT_ID} className={styles.digicert__icon} />
            {children}
        </div>
    );
};

export default DigicertView;
