import { initReactI18next } from 'react-i18next';
import i18next, { InitOptions, Resource, TFunction } from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const DEFAULT_LOCALE = 'en';

export const i18n = (resources: Resource): Promise<TFunction> => {
    const config: InitOptions = {
        defaultNS: 'common',
        load: 'languageOnly',
        nonExplicitWhitelist: false,
        fallbackLng: DEFAULT_LOCALE,
        debug: process.env.NODE_ENV === 'development',
        resources,
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        // react i18next special options (optional)
        react: {
            bindI18n: 'languageChanged loaded',
            nsMode: 'default',
            useSuspense: true,
        },
    };

    return i18next.use(Backend).use(LanguageDetector).use(initReactI18next).init(config);
};
