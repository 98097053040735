import React, { useEffect } from 'react';
import { resolveFavicons } from 'utils';
import { URL_APP_TYPE } from 'consts';

import { getAppType } from '../../utils';

const withFavicon = (Component: React.FC): React.FC => () => {
    useEffect(() => {
        const appType = getAppType();
        const map = Object.entries(URL_APP_TYPE).reduce((acc: any, item: any) => {
            const [key, value] = item;

            return { ...acc, [value]: key };
        }, {});

        resolveFavicons(map[appType]);
    }, []);

    return <Component />;
};

export default withFavicon;
