import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { collectError } from 'utils';

import { extendFxJwtToken } from '../../../../api/fx';
import { getSuccessLoginUrl } from '../../../../api/cx';
import { loginToOrganisation } from '../../shared/loginToOrganisation';
import { Organisation } from '../../../../api/types';
import MemberOrganisationsForm from '../../components/MemberOrganisationsForm';

interface MemberOrganisationsProps {
    organisations: Organisation[];
    organisationsIds: string[];
    setOrganisations: (organisation: Organisation[]) => void;
}

const MemberOrganisations: React.FC<MemberOrganisationsProps> = ({
    organisations,
    setOrganisations,
    organisationsIds,
}) => {
    const [formError, setFormError] = useState<string | null>(null);
    const [isFormLoading, setFormLoading] = useState(false);
    const { t } = useTranslation();

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();
        const { value } = e.currentTarget['organisationId'];

        if (!value) {
            return setFormError(t('Choose an action please'));
        }

        try {
            setFormLoading(true);
            const [{ redirectUrl }] = await Promise.all([getSuccessLoginUrl(value), extendFxJwtToken(value)]);

            window.location.assign(redirectUrl);
        } catch (error: unknown) {
            collectError(error);
            setFormError(error as string);
        } finally {
            setFormLoading(false);
        }
    };

    useEffect(() => {
        if (organisations.length) {
            return;
        }
        (async () => {
            try {
                await loginToOrganisation(setOrganisations, organisationsIds);
            } catch (error: unknown) {
                collectError(error);
            }
        })();
        // eslint-disable-next-line
    }, []);

    return organisations.length ? (
        <MemberOrganisationsForm
            onSubmit={onSubmit}
            isLoading={isFormLoading}
            options={organisations}
            formError={formError}
        />
    ) : null;
};

export default MemberOrganisations;
