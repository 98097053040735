import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import WhyLink from '../WhyLink';

const ConfirmationLinkExpiredMessage = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.root}>
            <p className={styles.bold}>{t('Your confirmation link has expired')}.</p>
            <span>{t('Please log in with your old password and try again')}. </span>
            <WhyLink place='WHY_TO_CHANGE_PASSWORD' />
        </div>
    );
};

export default ConfirmationLinkExpiredMessage;
