import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { collectError } from 'utils';

import { getToken } from '../../../../common/utils/routing';
import BackToLogin from '../../components/BackToLogin';
import Loader from '../../components/Loader';
import styles from './styles.module.scss';

interface TokenVerificationWrapperProps {
    onCatch?(error: any): void;
    onSuccess?(): void;
    verifyToken(token: string): Promise<any>;
}

const onSuccessPlaceholder = () => {
    // placeholder
};

const TokenVerificationWrapper: React.FC<PropsWithChildren<TokenVerificationWrapperProps>> = ({
    children,
    onCatch,
    onSuccess = onSuccessPlaceholder,
    verifyToken,
}) => {
    const [isVerified, setIsVerified] = useState(false);
    const [mistake, setMistake] = useState<string | null>(null);
    const { t } = useTranslation();

    useEffect(() => {
        (async () => {
            const token = getToken();

            if (token) {
                await verifyToken(token)
                    .then(onSuccess)
                    .catch((error: string | null) => {
                        collectError(error);
                        (onCatch || setMistake)(error);
                    });
            } else {
                setMistake(t('Token is not set.'));
            }
            setIsVerified(true);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isVerified) {
        return <Loader center />;
    }

    if (mistake) {
        return (
            <>
                <p className={styles.mistake} test-id='password-new-token-error'>
                    {mistake}
                </p>
                <BackToLogin />
            </>
        );
    }

    return children;
};

export default TokenVerificationWrapper;
