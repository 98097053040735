import React, { ChangeEventHandler, forwardRef } from 'react';
import { FormikValidationResult } from 'types';
import cn from 'classnames';

import { Validate } from '../../../../common/utils/validation';
import styles from './styles.module.scss';

export interface InputProps extends React.InputHTMLAttributes<any> {
    validate?: Validate;
    error?: boolean;
    setError?: (errors: Record<string, FormikValidationResult>) => void;
    onChange?: any;
    name: string;
    label: string;
    helperText?: string;
    inputProps?: { [key: string]: unknown };
    endAdornment?: React.ReactNode;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
    ({ endAdornment, error, helperText, inputProps = {}, label, onChange, setError, validate, ...rest }, ref) => {
        const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
            validate && setError && setError({ [rest.name]: validate(e.target.value) });
            onChange?.(e.target.value, e);
        };

        return (
            <div className={styles.input}>
                <span className={styles.input_label}>{label}</span>
                <div className={styles.input_wrapper}>
                    <input
                        {...rest}
                        className={cn(styles.input_input, { [styles.input_error]: error })}
                        onChange={handleChange}
                        {...inputProps}
                        ref={ref}
                    />
                    {endAdornment && <div className={styles.input_endAdornment}>{endAdornment}</div>}
                </div>
                {helperText && <span className={styles.input_label}>{helperText}</span>}
            </div>
        );
    },
);

export default Input;
