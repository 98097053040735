import { cookieKeys, getCookie } from 'utils';
import i18next from 'i18next';

import { EMPTY_OBJECT, HTTP_METHOD, HTTP_STATUS } from './constants';

interface CallApiOptions {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body?: Record<string, any>;
    headers?: Record<string, string>;
    method?: string;
}

export const callApiWithResponse = async (endpoint: string, options = EMPTY_OBJECT as CallApiOptions): Promise<any> => {
    const response = await fetch(endpoint, {
        headers: {
            'Content-Type': 'application/json',
            credentials: 'same-origin',
            'X-XSRF-TOKEN': getCookie(cookieKeys.FX_XSRF),
            ...(options.headers || EMPTY_OBJECT),
        },
        method: options.method || HTTP_METHOD.GET,
        ...(options.body ? { body: JSON.stringify(options.body) } : EMPTY_OBJECT),
    });

    if (response.status >= HTTP_STATUS.INTERNAL_SERVER_ERROR) {
        throw new Error(`${i18next.t('Sorry, something went wrong, please try again later')}.`);
    }

    if (!response.ok) {
        throw await response.json();
    }

    if (response.headers.get('content-type')?.includes('application/json')) {
        const data = await response.json();

        return { data, response };
    }

    return EMPTY_OBJECT;
};

export const callApi = async (...args: Parameters<typeof callApiWithResponse>): Promise<any> => {
    const { data } = await callApiWithResponse(...args);
    return data;
};

export const post = (
    endpoint: string,
    body?: CallApiOptions['body'],
    headers?: CallApiOptions['headers'],
): Promise<any> => callApi(endpoint, { body, method: HTTP_METHOD.POST, headers });
