import i18next from 'i18next';
import { collectError, isObject } from 'utils';

import { verifyTokenToChangeEmail } from '../../../api/fx';
import { getToken } from '../../../common/utils/routing';

export const verifyTokenToChangeEmailOnPageLoad = async (): Promise<string | undefined> => {
    try {
        const token = getToken();

        if (!token) {
            return i18next.t('Token is not set.');
        }

        await verifyTokenToChangeEmail(token);
    } catch (error: unknown) {
        collectError(error);

        if (isObject(error) && 'message' in error) {
            return error.message as string | undefined;
        }
    }
};
