export enum FormRoutes {
    'login' = '/login',
    'forgotPassword' = '/password/forgot',
    'newPassword' = '/password/new',
    'newEmail' = '/email/new',
    'legacyPassword' = '/password/legacy',
    'verifyPasswordToken' = '/password/verify',
    'verifyPasswordSuccess' = '/password/verify-success',
    'organisations' = '/choose-organisation',
    'currentSession' = '/current-session',
    'checkInbox' = '/check-inbox',
    'checkInboxError' = '/check-inbox-error',
}

export const getParam = (paramName: string): string | null => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(paramName);
};

export const getToken = (): string => getParam('token') || '';

const updateHistory = (pathname: string, search?: string) => {
    window.history.pushState({ pathname }, '', `${process.env.PUBLIC_URL}${pathname}${search || ''}`);
};

const router = {
    pathname: window.location.pathname.replace(process.env.PUBLIC_URL, ''),
    init(): void {
        if (Object.values(FormRoutes).includes(this.pathname as any)) {
            updateHistory(this.pathname, window.location.search);
        }
        window.addEventListener('popstate', ({ state }: PopStateEvent) => {
            if (Object.values(FormRoutes).includes(state?.pathname)) {
                this.notify(state.pathname);
            }
        });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    listeners: [] as ((pathname: string, search: URLSearchParams) => any)[],
    go(pathname: FormRoutes, search?: string): void {
        updateHistory(pathname, search);
        this.notify(pathname);
        this.pathname = pathname;
    },
    notify(pathname: string): void {
        this.listeners.forEach(f => f(pathname, new URLSearchParams(window.location.search)));
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    subscribe(f: (pathname: string, search: URLSearchParams) => void): any {
        f(this.pathname, new URLSearchParams(window.location.search));
        this.listeners.push(f);
    },
};

router.init();

export default router;
