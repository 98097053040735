export const JWT_AUTH_ERRORS = {
    'access.denied': 'Access denied!',
    'inactive.session':
        'You have been logged out automatically, because the username and password you used to login have been used from a different device. If you require more users, please contact your Account Manager.',
    'not.main.contact': 'Your subscription only allows you to log in as the main contact.',
    'invalid.account': 'Your subscription has expired. Please speak to your Account Manager to renew.',
    'inactive.subscription':
        'Your account has been invalidated. Please call your Account Manager to re-activate your account!',
    'too.many.login.requests': 'Please try to login later.',
} as const;

export const SINGLE_ORGANISATION = 1;
