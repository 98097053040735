import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import image from '../../../../assets/images/chrome.svg';

const BROWSER_LINK = 'https://www.google.com/chrome/';

const BrowserMessageView: React.FC = () => {
    const { t } = useTranslation('loginSide');

    return (
        <div className={styles.login__browser}>
            <img src={image} width='38' height='38' className={styles.login__browserLogo} alt='Chrome Browser Logo' />
            <div>
                <p className={styles.login__browserText}>{t('The application works best')}</p>
                <p className={styles.login__browserText}>{t('using Google Chrome')}</p>
                <a target='_blank' rel='noopener noreferrer' href={BROWSER_LINK} da-id='download-browser-link'>
                    <span>{t('Download')}</span>
                </a>
            </div>
        </div>
    );
};

export default BrowserMessageView;
