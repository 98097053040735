import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { formatLastLoginDate } from '../../utils/lastLogin';
import { LastLogin } from '../../../../api/types';
import { useMobile } from '../../hooks';
import AccessAlarm from './AccessAlarm';
import DesktopMac from './DesktopMac';
import Public from './Public';
import styles from './styles.module.scss';
import SubmitButton from '../SubmitButton';

interface CurrentSessionViewProps {
    lastLogin: NonNullable<LastLogin>;
    isSubmitting: boolean;
    onSubmit: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
}

const CurrentSessionView: React.FC<CurrentSessionViewProps> = ({ lastLogin, isSubmitting, onSubmit }) => {
    const { t } = useTranslation();
    const isMobile = useMobile();

    return (
        <div>
            <div className={cn(styles.header, styles.bold)}>
                <span>
                    {t(
                        'Oops - it looks like someone is already logged into your account on another device. If you require more users, please contact the customer service department.',
                    )}
                </span>
            </div>
            <div className={styles.body}>
                <span>{t('Current session')}:</span>
                <ul className={styles.list}>
                    <li className={styles.listItem}>
                        <AccessAlarm className={styles.listIcon} />
                        <div className={styles.listItemTitle}>
                            <span>{t('Login time')}:</span>
                        </div>
                        <span>{formatLastLoginDate(lastLogin)}</span>
                    </li>
                    <li className={styles.listItem}>
                        <DesktopMac className={styles.listIcon} />
                        <div className={styles.listItemTitle}>
                            <span>{t('OS')}:</span>
                        </div>
                        <span>{lastLogin.os ?? '-'}</span>
                    </li>
                    <li className={styles.listItem}>
                        <Public className={styles.listIcon} />
                        <div className={styles.listItemTitle}>
                            <span>{t('Browser')}:</span>
                        </div>
                        <span>{lastLogin.browser ?? '-'}</span>
                    </li>
                </ul>
                <span className={styles.bold}>{t('Do you want to continue?')}</span>
            </div>
            {isMobile ? (
                <>
                    <SubmitButton
                        className={styles.mobile}
                        da-id='login-submit'
                        onClick={onSubmit}
                        progress={isSubmitting}
                        test-id='login-submit'
                    >
                        {t('Log In to Mobile Version')}
                    </SubmitButton>
                    <SubmitButton
                        da-id='login-submit'
                        onClick={onSubmit}
                        progress={isSubmitting}
                        test-id='login-submit'
                        version='outlined'
                    >
                        {t('Log In to Desktop Version')}
                    </SubmitButton>
                </>
            ) : (
                <SubmitButton
                    da-id='login-submit'
                    onClick={onSubmit}
                    progress={isSubmitting}
                    test-id='login-submit'
                    version='contained'
                >
                    {t('Log In')}
                </SubmitButton>
            )}
        </div>
    );
};

export default CurrentSessionView;
