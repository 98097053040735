import APP_TYPE from "consts/build/esm/APP_TYPE.js";
import URL_APP_TYPE from "consts/build/esm/URL_APP_TYPE.js";
var changeHref = function changeHref(prefix) {
  return function (elem) {
    if (typeof elem.getAttribute('href') !== 'string') {
      return;
    }
    elem.setAttribute('href', elem.getAttribute('href').replace('cx-favicon', "".concat(prefix, "-favicon")));
  };
};
var resolveFavicons = function resolveFavicons(appName) {
  var SELECTOR = '[href*="cx-favicon"]';
  switch (appName) {
    case APP_TYPE.FLS:
      document.querySelectorAll(SELECTOR).forEach(changeHref(URL_APP_TYPE[APP_TYPE.FLS]));
      break;
    case APP_TYPE.LOGIQUANTA:
      document.querySelectorAll(SELECTOR).forEach(changeHref(URL_APP_TYPE[APP_TYPE.LOGIQUANTA]));
      break;
    case APP_TYPE.CUSTOMER_PORTAL:
      document.querySelectorAll(SELECTOR).forEach(changeHref(URL_APP_TYPE[APP_TYPE.CUSTOMER_PORTAL]));
      break;
    case APP_TYPE.HX:
    case APP_TYPE.FSDF:
    case APP_TYPE.SF:
      document.querySelectorAll(SELECTOR).forEach(changeHref(URL_APP_TYPE[APP_TYPE.HX]));
      break;
    case APP_TYPE.INTEGRA:
    case APP_TYPE.LENDER_PORTAL:
      document.querySelectorAll(SELECTOR).forEach(changeHref(URL_APP_TYPE[APP_TYPE.INTEGRA]));
      break;
    case APP_TYPE.CXNA_CARRIER:
    case APP_TYPE.CXNA_BROKER:
      document.querySelectorAll(SELECTOR).forEach(changeHref(URL_APP_TYPE[APP_TYPE.CXNA_CARRIER]));
      break;
    default:
      return;
  }
};
export default resolveFavicons;