import React, { PropsWithChildren } from 'react';
import cn from 'classnames';

import { OptionalClassName } from '../../types/OptionalClassName';
import styles from './styles.module.scss';

const FormError: React.FC<PropsWithChildren<OptionalClassName>> = ({ className, children, ...rest }) => {
    return (
        <div>
            <span className={cn(className, styles.formError)} {...rest}>
                {children}
            </span>
        </div>
    );
};

export default FormError;
