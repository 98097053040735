import cn from 'classnames';
import React, { PropsWithChildren } from 'react';

import CircularProgress from './CircularProgress';
import styles from './styles.module.scss';

export type Version = 'contained' | 'outlined';

export interface SubmitButtonProps extends React.ButtonHTMLAttributes<any> {
    progress: boolean;
    version?: Version;
}

const SubmitButton: React.FC<PropsWithChildren<SubmitButtonProps>> = ({
    children,
    className,
    progress,
    version = 'contained',
    ...rest
}) => {
    return (
        <button {...rest} className={cn(styles.button, styles[version], className)} type='submit' disabled={progress}>
            {progress ? <CircularProgress version={version} /> : children}
        </button>
    );
};

export default SubmitButton;
