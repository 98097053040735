import React, { useState } from 'react';

import styles from './styles.module.scss';
import { RadioOption } from '../../types/RadioOption';

interface RadioGroupProps {
    'test-id'?: string;
    name: string;
    options: RadioOption[];
    defaultValue?: any;
}

const RadioGroup: React.FC<RadioGroupProps> = ({ options, name, defaultValue, ...rest }) => {
    const [checked, setChecked] = useState(defaultValue);

    const onChange: React.ChangeEventHandler<HTMLInputElement> = e => {
        e.stopPropagation();
        e.target.value && setChecked(e.target.value);
    };

    return (
        <div className={styles.root}>
            {options.map(({ value, label }) => (
                <label htmlFor={value.toString()} key={value} className={styles.option}>
                    <span className={styles.inputWrapper}>
                        <input
                            onChange={onChange}
                            checked={checked === value.toString()}
                            className={styles.input}
                            name={name}
                            test-id={`${rest['test-id'] || ''}${value}`}
                            type='radio'
                            value={value.toString()}
                            id={value.toString()}
                        />
                    </span>
                    <span className={styles.label}>{label}</span>
                </label>
            ))}
        </div>
    );
};

export default RadioGroup;
