import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikValidationResult } from 'types';

import { useMobile } from '../../hooks';
import FormError from '../FormError';
import Input from '../Input';
import LinkButton from '../LinkButton';
import MessageView from '../../../loginSide/components/MessageView';
import styles from './styles.module.scss';
import SubmitButton from '../SubmitButton';

interface LoginFormProps {
    errors: Record<string, FormikValidationResult>;
    formError?: React.ReactElement | string;
    isSubmitting: boolean;
    message: string | null;
    onSubmit(event: any): Promise<void>;
    switchToForgetPassword(): void;
}

export const fieldNames = { login: 'login', password: 'password' };
const FORGOT_EMAIL_HELP_LINK =
    'https://help.transportexchangegroup.com/hc/en-gb/articles/6805389262492-Your-login-is-changing-on-the-Exchange#h_01GVDW1CWR014Z5AFEBXFYGAQC';

const LoginForm = forwardRef<HTMLInputElement, LoginFormProps>(
    ({ errors, formError, isSubmitting, message, onSubmit, switchToForgetPassword }, ref) => {
        const { t } = useTranslation();
        const { t: tMessages } = useTranslation('messages');
        const isError = !!formError || !!Object.keys(errors).length;
        const isMobile = useMobile();

        return (
            <form onSubmit={onSubmit} test-id='login-form'>
                {message && <MessageView>{tMessages(message)}</MessageView>}
                {isError && (
                    <FormError test-id='login-form-error'>
                        {formError || errors[fieldNames.login] || errors[fieldNames.password]}
                    </FormError>
                )}
                <LinkButton
                    className={styles.link}
                    href={FORGOT_EMAIL_HELP_LINK}
                    target='_blank'
                    rel='noopener noreferrer'
                    test-id='login-button-forgot-email'
                    da-id='login-button-forgot-email'
                >
                    {t('Forgot Email')}?
                </LinkButton>
                <Input
                    autoFocus
                    error={!!errors[fieldNames.login] || !!formError}
                    name={fieldNames.login}
                    label={t('Email')}
                    test-id='login-field-email'
                    placeholder={t('My email is...')}
                />
                <LinkButton
                    className={styles.link}
                    onClick={switchToForgetPassword}
                    test-id='login-button-forgot-password'
                    da-id='login-button-forgot-password'
                >
                    {t('Forgot Password')}?
                </LinkButton>
                <Input
                    ref={ref}
                    type='password'
                    error={!!errors[fieldNames.password] || !!formError}
                    name={fieldNames.password}
                    label={t('Password')}
                    test-id='login-field-password'
                    placeholder={t('Enter your password')}
                />
                {isMobile ? (
                    <>
                        <SubmitButton
                            className={styles.mobile}
                            da-id='login-submit'
                            data-view='mobile'
                            progress={isSubmitting}
                            test-id='login-submit'
                        >
                            {t('Log In to Mobile Version')}
                        </SubmitButton>
                        <SubmitButton
                            da-id='login-submit'
                            progress={isSubmitting}
                            test-id='login-submit'
                            version='outlined'
                        >
                            {t('Log In to Desktop Version')}
                        </SubmitButton>
                    </>
                ) : (
                    <SubmitButton
                        da-id='login-submit'
                        progress={isSubmitting}
                        test-id='login-submit'
                        version='contained'
                    >
                        {t('Log In')}
                    </SubmitButton>
                )}
            </form>
        );
    },
);

export default LoginForm;
