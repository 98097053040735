import React from 'react';

const Public: React.FC<{ className?: string }> = props => {
    return (
        <svg viewBox='0 0 24 24' {...props}>
            <path d='M5 4v2h14V4H5zm0 10h4v6h6v-6h4l-7-7-7 7z' />
        </svg>
    );
};

export default Public;
