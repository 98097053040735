import React from 'react';
import { useTranslation } from 'react-i18next';

import WhyLink from '../WhyLink';

const ReasonToChangeLegacyPasswordMessage = () => {
    const { t } = useTranslation();

    return (
        <>
            <span>{t('For security reasons, you need to update your password')}. </span>
            <WhyLink place='PASSWORD_CHANGE_SCREEN' />
        </>
    );
};

export default ReasonToChangeLegacyPasswordMessage;
