import React from 'react';
import { useTranslation } from 'react-i18next';

import { Meta } from '../../../../api/types';
import styles from './styles.module.scss';

interface SupportViewProps {
    data: Meta;
}

const SupportView: React.FC<SupportViewProps> = ({ data }) => {
    const { t } = useTranslation('loginSide');
    const { label, helpLink, phone, ticketUrl } = data;

    if (phone) {
        return (
            <div className={styles.root}>
                <div className={styles.login__queriesText}>{t('If you have any queries, please contact us')}:</div>
                <div className={styles.login__contacts}>
                    <div className={styles.login__contact}>
                        <span className={styles.login__phone}>
                            <b>
                                {label && `${label}: `}
                                {phone}
                            </b>
                        </span>
                        <br />
                        <span>{t('or submit a')}</span>&nbsp;
                        <a href={ticketUrl} target='_blank' rel='noopener noreferrer' da-id='support-ticket-link'>
                            <span>{t('support ticket')}.</span>
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    if (helpLink) {
        return (
            <div className={styles.root}>
                <div className={styles.login__contact}>
                    <span>{t('If you are unable to login, please visit our')} </span>
                </div>
                <a href={helpLink} target='_blank' rel='noopener noreferrer' da-id='help-center-link'>
                    <span>{t('help centre')}.</span>
                </a>
            </div>
        );
    }

    return null;
};

export default SupportView;
