import trimPayload from './trimPayload';
import isString from './isString';
import isArray from './isArray';
import isObject from './isObject';
var trimValue = function trimValue(value) {
  if (isString(value)) {
    return value.trim();
  }
  if (isArray(value)) {
    return value.map(trimValue);
  }
  if (isObject(value)) {
    return trimPayload(value);
  }
  return value;
};
export default trimValue;