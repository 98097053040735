import React, { useState } from 'react';
import { collectError, isString } from 'utils';

import { getDataFromForm } from '../../shared/form';
import { isFormValid, isPasswordCorrect, validateNewPasswordForm } from './validation';
import { NewPasswordFormData } from '../../types/NewPassword';
import NewPassword, { fieldNames, NewPasswordFormErrors } from '../../components/NewPassword';
import router, { FormRoutes } from '../../../../common/utils/routing';

enum State {
    submitting,
    editing,
    complete,
}

interface NewPasswordContainerProps {
    message?: JSX.Element;
    changePassword(newPassword: string): Promise<any>;
}

const NewPasswordContainer: React.FC<NewPasswordContainerProps> = ({ message, changePassword }) => {
    const [state, setState] = useState(State.editing);
    const [formApiError, setFormApiError] = useState<string | null>(null);
    const [errors, setErrors] = useState<NewPasswordFormErrors>(
        () => validateNewPasswordForm({ [fieldNames.newPassword]: '', [fieldNames.confirmPassword]: '' }).errors,
    );

    const validateOnChange = (event: React.FormEvent<HTMLFormElement>) => {
        setFormApiError(null);
        const { errors } = validateNewPasswordForm(getDataFromForm<NewPasswordFormData>(event));
        setErrors(errors);
    };

    async function onSubmit(event: React.FormEvent<HTMLFormElement>): Promise<void> {
        event.preventDefault();

        const data = getDataFromForm<NewPasswordFormData>(event);
        const { errors, isValid } = validateNewPasswordForm(data);

        if (!isValid) {
            return setErrors(errors);
        }

        try {
            setState(State.submitting);
            const { passwordVerificationRequired } = await changePassword(data[fieldNames.newPassword]);

            setState(State.complete);

            if (passwordVerificationRequired) {
                return router.go(FormRoutes.checkInbox);
            }

            router.go(FormRoutes.login, '?message=password.changed');
        } catch (error: any) {
            setFormApiError(isString(error.message) ? error.message : error);
            setState(State.editing);
            collectError(error);
        }
    }

    const formError =
        formApiError ||
        (isPasswordCorrect(errors[fieldNames.newPassword]) && errors[fieldNames.confirmPassword]) ||
        null;
    const isValid = isFormValid(errors);

    return (
        <NewPassword
            errors={errors}
            formError={formError}
            isSubmitting={state === State.submitting}
            message={message}
            onChange={validateOnChange}
            onSubmit={onSubmit}
            isError={!isValid || !!formApiError}
            isSubmitDisable={!isValid}
        />
    );
};

export default NewPasswordContainer;
