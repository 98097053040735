import React, { PropsWithChildren } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import App from './App';
import ErrorBoundary from './ErrorBoundary';

const LocalGoogleReCaptchaProvider = GoogleReCaptchaProvider as React.ComponentType<
    PropsWithChildren<{ reCaptchaKey: string }>
>;

const Root: React.FC = () => {
    return (
        <ErrorBoundary>
            <LocalGoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY!}>
                <App />
            </LocalGoogleReCaptchaProvider>
        </ErrorBoundary>
    );
};

export default Root;
