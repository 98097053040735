import { ORGANIZATION_HEADER_NAME, CX_AUTH_ENDPOINT } from './constants';
import {
    CxChangeLegacyPasswordApiResponse,
    CxForceLoginApiResponse,
    CxForgotPasswordApiResponse,
    CxJwtApiResponse,
    CxLoginApiResponse,
    CxLoginRequestBody,
} from './types';
import { callApi, callApiWithResponse, post } from './config';
import { getToken } from '../common/utils/routing';

export const loginToCXAuthRequest = (body: CxLoginRequestBody): Promise<CxLoginApiResponse> => {
    return callApiWithResponse(`${CX_AUTH_ENDPOINT}/login`, { body, method: 'post' });
};

export const changeCxLegacyPassword = (newPassword: string): Promise<CxChangeLegacyPasswordApiResponse> => {
    return post(`${CX_AUTH_ENDPOINT}/password/change`, {
        newPassword,
    });
};

export const verifyTokenOnLegacyPasswordChange = (token: string): Promise<unknown> => {
    return callApi(`${CX_AUTH_ENDPOINT}/password/verify?token=${encodeURIComponent(token)}`);
};

export const checkTokenBeforeLegacyPasswordChange = (): Promise<unknown> => {
    return callApi(`${CX_AUTH_ENDPOINT}/password/check?token=${encodeURIComponent(getToken())}`);
};

export const forceLoginToCXRequest = (): Promise<CxForceLoginApiResponse> => {
    return post(`${CX_AUTH_ENDPOINT}/force`);
};

export const forgotPasswordToCXAuthRequest = (login: string): Promise<CxForgotPasswordApiResponse> => {
    return post(`${CX_AUTH_ENDPOINT}/password/forgot`, {
        login,
    });
};

export const getSuccessLoginUrl = (chosenOrganizationId: string): Promise<CxJwtApiResponse> => {
    return callApi('/auth/cx-jwt', {
        headers: { [ORGANIZATION_HEADER_NAME]: chosenOrganizationId },
    });
};
