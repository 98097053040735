import type { ValueOf } from 'types';
import { APP_TYPE, REQUEST_STATUSES, URL_APP_TYPE } from 'consts';

const LOGIQUANTA_OR_FLS_DOMAIN = 'haulageexchange.co.uk';

export const isLogiquantaOrFlsDomain = () => {
    const hostname = window.location.hostname;

    if (hostname.includes(LOGIQUANTA_OR_FLS_DOMAIN)) {
        return (
            hostname.startsWith(URL_APP_TYPE[APP_TYPE.LOGIQUANTA]) || hostname.startsWith(URL_APP_TYPE[APP_TYPE.FLS])
        );
    }

    return false;
};

export const queryParams = (search: string) => (key: string) => () => new URLSearchParams(search).get(key);
const querySearchParams = queryParams(window.location.search);
export const checkFlsAppTypeByDomain = () => window.location.origin.includes('fls');
export const queryAppType = () => {
    if (isLogiquantaOrFlsDomain()) {
        return window.location.hostname.split('.')[0];
    }

    return querySearchParams('app')();
};
export const validateAppType =
    (types: ValueOf<typeof URL_APP_TYPE>[]) => (appType: any) => (): ValueOf<typeof URL_APP_TYPE> => {
        const isFlsAppType = checkFlsAppTypeByDomain();

        if (isFlsAppType) {
            return URL_APP_TYPE[APP_TYPE.FLS];
        }

        const isDefault = !appType || !types.includes(appType);

        return isDefault ? URL_APP_TYPE[APP_TYPE.CX] : appType;
    };

export const getAppType = validateAppType(Object.values(URL_APP_TYPE))(queryAppType());

export const createDefaultMeta = (appType: ValueOf<typeof URL_APP_TYPE>) => () => ({
    meta: null,
    status: REQUEST_STATUSES.NOT_STARTED,
    appType,
});
