import React, { useState } from 'react';
import { collectError } from 'utils';

import PasswordVerify from '../../components/PasswordVerify';
import router, { FormRoutes, getToken } from '../../../../common/utils/routing';
import { verifyTokenOnLegacyPasswordChange } from '../../../../api/cx';

const PasswordVerifyContainer = () => {
    const token = getToken();
    const [error, setError] = useState<string | undefined>();
    const [loading, setLoading] = useState<boolean>(false);

    const onClick = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
        try {
            setLoading(true);
            await verifyTokenOnLegacyPasswordChange(token);
            router.go(FormRoutes.verifyPasswordSuccess);
        } catch (error: any) {
            setError(error);
            collectError(error);
        } finally {
            setLoading(false);
        }
    };

    return <PasswordVerify error={error} loading={loading} onClick={onClick} />;
};

export default PasswordVerifyContainer;
