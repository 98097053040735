/* eslint-disable no-param-reassign */

var load = function load(tag, url) {
  return new Promise(function (resolve, reject) {
    tag.onload = function () {
      return resolve(undefined);
    };
    tag.onerror = function () {
      return reject(new Error("Loading ".concat(url, " failed.")));
    };
    return document.head.appendChild(tag);
  });
};
export default load;