import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import LinkButton from '../LinkButton';
import router, { FormRoutes } from '../../../../common/utils/routing';

import styles from './styles.module.scss';

const openLoginPage: React.MouseEventHandler<HTMLButtonElement> = () => {
    router.go(FormRoutes.login);
};

type BackToLoginProps = {
    secondary?: boolean;
    right?: boolean;
};

const BackToLogin: React.FC<BackToLoginProps> = ({ secondary, right }) => {
    const { t } = useTranslation();

    return (
        <LinkButton
            className={cn(styles.link, { [styles.linkSecondary]: secondary, [styles.right]: right })}
            onClick={openLoginPage}
            test-id='return-to-login'
        >
            {t('Cancel and return to login')}
        </LinkButton>
    );
};

export default BackToLogin;
