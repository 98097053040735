import React from 'react';
import { useTranslation } from 'react-i18next';
import WhyLink from '../WhyLink';

import styles from './styles.module.scss';

const LegacyPasswordErrorMessage = () => {
    const { t } = useTranslation();

    return (
        <>
            <p className={styles.bold}>{t('Please check your inbox')}.</p>
            <span>{t('You need to confirm your password update before logging in')}. </span>
            <WhyLink place='CHECK_INBOX_ERROR_SCREEN' />
        </>
    );
};

export default LegacyPasswordErrorMessage;
