const MOBILE_VERSION_COOKIE = 'useMobileVersion';

const getHostname = () => {
    const { hostname } = window.location;

    if (hostname.indexOf('.') !== -1 && hostname.indexOf('.') !== hostname.lastIndexOf('.')) {
        return hostname.substring(hostname.indexOf('.') + 1);
    }

    return hostname;
};

const getUseMobileVersionCookieString = (cookieValue: 'true' | 'false') => {
    const host = getHostname();
    const domain = host === 'localhost' ? '' : ` domain=${host}`;

    return `${MOBILE_VERSION_COOKIE}=${cookieValue}; path=/;${domain}`;
};

export const setCxDesktopView = () => {
    document.cookie = getUseMobileVersionCookieString('false');
};

export const setCxMobileView = () => {
    document.cookie = getUseMobileVersionCookieString('true');
};

const isCxMobileView = ({ submitter }: any) => submitter.dataset.view === 'mobile';

const setMobileOrDesktopCookie = (isCxMobileView: boolean) => (isCxMobileView ? setCxMobileView() : setCxDesktopView());

export const setAppView = (nativeEvent: any) => setMobileOrDesktopCookie(isCxMobileView(nativeEvent));
