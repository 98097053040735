import { extendFxJwtToken, getAccountInfo } from '../../../api/fx';
import { getSuccessLoginUrl } from '../../../api/cx';
import { Organisation } from '../../../api/types';
import { SINGLE_ORGANISATION } from './constants';
import router, { FormRoutes } from '../../../common/utils/routing';

export const loginToOrganisation = async (
    handleMultipleOrganisation: (organisations: Organisation[]) => void,
    organisationsIds: string[] = [],
): Promise<void> => {
    if (organisationsIds.length === SINGLE_ORGANISATION) {
        const [{ redirectUrl }] = await Promise.all([
            getSuccessLoginUrl(organisationsIds[0]),
            extendFxJwtToken(organisationsIds[0]),
        ]);

        return window.location.assign(redirectUrl);
    }

    const {
        type,
        payload: { organisations },
    } = await getAccountInfo();

    if (type === 'ERROR') {
        return window.location.assign('/');
    }

    if (organisations.length > SINGLE_ORGANISATION) {
        handleMultipleOrganisation(organisations);
        return router.go(FormRoutes.organisations);
    }

    const [{ redirectUrl }] = await Promise.all([
        getSuccessLoginUrl(organisations[0].organisationId),
        extendFxJwtToken(organisations[0].organisationId),
    ]);

    window.location.assign(redirectUrl);
};
