import React, { useContext } from 'react';
import { REQUEST_STATUSES } from 'consts';

import { metaContext } from '../../../meta/constants';
import { useMobile } from '../../../loginForm/hooks';
import DefaultAdView from '../../components/DefaultAdView';
import IframeIntegration from '../../components/IframeIntegration';
import withBackground from '../../hocs/withBackground';

const DefaultAd: React.FC = () => {
    const isMobile = useMobile();
    const isDesktop = !isMobile;
    const {
        data: { appType, meta, status },
    } = useContext(metaContext);
    const isReady = status !== REQUEST_STATUSES.NOT_STARTED && status !== REQUEST_STATUSES.PENDING;

    if (!isReady) {
        return null;
    }

    return meta?.loginPageAdActive && isDesktop ? (
        <IframeIntegration loginPageAdUrl={meta.loginPageAdUrl} />
    ) : (
        <DefaultAdView appType={appType} />
    );
};

export default withBackground(DefaultAd);
