import React, { useEffect, useState } from 'react';

import { changeCxLegacyPassword, checkTokenBeforeLegacyPasswordChange } from '../../../../api/cx';
import { LastLogin, Organisation } from '../../../../api/types';
import { resetPassword, verifyTokenOnPasswordReset } from '../../../../api/fx';
import { useCheckSession, useDocumentTitle } from '../../hooks';
import ChangeEmail from '../ChangeEmail';
import ConfirmationLinkExpiredMessage from '../../components/ConfirmationLinkExpiredMessage/ConfirmationLinkExpiredMessage';
import CurrentSession from '../CurrentSession';
import ForgotPasswordForm from '../ForgotPasswordFormContainer';
import LegacyPasswordErrorMessage from '../../components/LegacyPasswordMessages/LegacyPasswordErrorMessage';
import LegacyPasswordSuccessMessage from '../../components/LegacyPasswordMessages/LegacyPasswordErrorMessage';
import Loader from '../../components/Loader';
import LoginFormContainer from '../LoginFormContainer';
import MemberOrganisations from '../MemberOrganisation';
import NewPasswordContainer from '../NewPasswordContainer';
import NoConfirmationFromEmailMessage from '../../components/LegacyPasswordMessages/NoConfirmationFromEmailMessage';
import PasswordVerifyContainer from '../PasswordVerifyContainer';
import PasswordVerifySuccessScreenContainer from '../PasswordVerifySuccessScreenContainer';
import ReasonToChangeLegacyPasswordMessage from '../../components/LegacyPasswordMessages/ReasonToChangeLegacyPasswordMessage';
import router, { FormRoutes } from '../../../../common/utils/routing';
import TokenVerificationWrapper from '../TokenVerificationWrapper';

const LoginPage: React.FC = () => {
    const [lastLogin, setLastLogin] = useState<LastLogin>(null);
    const [organisationsIds, setOrganisationsIds] = useState<string[]>([]);
    const [globalError, setGlobalError] = useState<React.ReactElement | string>('');
    const [beta, setBeta] = useState<boolean | null>(null);
    const [confirmationTimeHasExpired, setConfirmationTimeHasExpired] = useState(false);
    const [pathname, setPathname] = useState('');
    const [organisations, setOrganisations] = useState<Organisation[]>([]);

    useEffect(() => {
        router.subscribe(setPathname);
        if (!Object.values(FormRoutes).some(route => router.pathname === route)) {
            router.go(FormRoutes.login);
        }
    }, []);

    useDocumentTitle();

    const isSessionChecked = useCheckSession();

    if (!isSessionChecked) {
        return <Loader center />;
    }
    if (pathname === FormRoutes.forgotPassword) {
        return <ForgotPasswordForm />;
    }
    if (pathname === FormRoutes.login) {
        return (
            <LoginFormContainer
                globalError={globalError}
                setBeta={setBeta}
                setConfirmationTimeHasExpired={setConfirmationTimeHasExpired}
                setGlobalError={setGlobalError}
                setLastLogin={setLastLogin}
                setOrganisations={setOrganisations}
                setOrganisationsIds={setOrganisationsIds}
            />
        );
    }
    if (pathname === FormRoutes.newPassword) {
        return (
            <TokenVerificationWrapper verifyToken={verifyTokenOnPasswordReset}>
                <NewPasswordContainer changePassword={resetPassword} />
            </TokenVerificationWrapper>
        );
    }
    if (pathname === FormRoutes.legacyPassword) {
        return (
            <NewPasswordContainer
                changePassword={changeCxLegacyPassword}
                message={
                    confirmationTimeHasExpired ? (
                        <NoConfirmationFromEmailMessage />
                    ) : (
                        <ReasonToChangeLegacyPasswordMessage />
                    )
                }
            />
        );
    }
    if (pathname.includes(FormRoutes.newEmail)) {
        return <ChangeEmail />;
    }
    if (pathname === FormRoutes.currentSession && lastLogin) {
        return (
            <CurrentSession
                beta={beta}
                lastLogin={lastLogin}
                organisationsIds={organisationsIds}
                setOrganisations={setOrganisations}
            />
        );
    }
    if (pathname === FormRoutes.verifyPasswordToken) {
        return (
            <TokenVerificationWrapper
                verifyToken={checkTokenBeforeLegacyPasswordChange}
                onCatch={() => {
                    setGlobalError(<ConfirmationLinkExpiredMessage />);
                    router.go(FormRoutes.login);
                }}
            >
                <PasswordVerifyContainer />
            </TokenVerificationWrapper>
        );
    }
    if (pathname === FormRoutes.organisations) {
        return (
            <MemberOrganisations
                organisations={organisations}
                organisationsIds={organisationsIds}
                setOrganisations={setOrganisations}
            />
        );
    }
    if (pathname === FormRoutes.checkInbox) {
        return <LegacyPasswordSuccessMessage />;
    }
    if (pathname === FormRoutes.checkInboxError) {
        return <LegacyPasswordErrorMessage />;
    }
    if (pathname === FormRoutes.verifyPasswordSuccess) {
        return <PasswordVerifySuccessScreenContainer />;
    }
    return null;
};

export default LoginPage;
