import React, { ErrorInfo, PropsWithChildren } from 'react';
import { collectError } from 'utils';

class ErrorBoundary extends React.Component<PropsWithChildren> {
    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        collectError(error, errorInfo);
    }

    render() {
        return this.props.children;
    }
}

export default ErrorBoundary;
