import React from 'react';

import styles from './styles.module.scss';

interface IframeIntegrationProps {
    loginPageAdUrl: string;
}

const IframeIntegration: React.FC<IframeIntegrationProps> = ({ loginPageAdUrl }) => {
    return (
        <div className={styles.root}>
            <iframe
                className={styles.iframe}
                title='advertisement'
                name='advertisement'
                loading='lazy'
                src={loginPageAdUrl}
            />
        </div>
    );
};

export default IframeIntegration;
