import { createContext } from 'react';
import { REQUEST_STATUSES, URL_APP_TYPE } from 'consts';
import type { ValueOf } from 'types';

import { createDefaultMeta, getAppType } from './utils';
import { Meta } from '../../api/types';

export interface MetaState {
    meta: Meta | null;
    status: ValueOf<typeof REQUEST_STATUSES>;
    appType: ValueOf<typeof URL_APP_TYPE>;
}

export interface MetaContext {
    data: MetaState;
    updateMeta: (appType: MetaState['appType']) => void;
}

export const metaContext = createContext<MetaContext>({
    data: createDefaultMeta(getAppType())(),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateMeta: () => {},
});
