import { URL_APP_TYPE } from 'consts';
import { ValueOf } from 'types';

import {
    FxAccountInfoApiResponse,
    FxForgotPasswordApiResponse,
    FxLoginApiResponse,
    FxLoginRequestBody,
    FxResetPasswordApiResponse,
    MetaApiResponse,
} from './types';
import {
    FORCE_LOGIN_BODY,
    FX_AUTH_ENDPOINT,
    META_ENDPOINT,
    NOT_FORCE_LOGIN_BODY,
    ORGANIZATION_HEADER_NAME,
} from './constants';
import { callApi, post } from './config';
import { getParam, getToken } from '../common/utils/routing';

export const loginRequest = (body: FxLoginRequestBody): Promise<FxLoginApiResponse> => {
    return post(`${FX_AUTH_ENDPOINT}/signin`, body);
};

export const resetPassword = (newPassword: string): Promise<FxResetPasswordApiResponse> => {
    return post(`${FX_AUTH_ENDPOINT}/password/reset`, {
        newPassword,
        token: getToken(),
        email: getParam('email'),
    });
};

export const verifyTokenOnPasswordReset = (token: string): Promise<unknown> => {
    return post(`${FX_AUTH_ENDPOINT}/password/reset/token/verify`, {
        token,
        email: getParam('email'),
    });
};

export const verifyTokenToChangeEmail = (token: string): Promise<unknown> => {
    return post(`${FX_AUTH_ENDPOINT}/email/change`, {
        token,
    });
};

export const forceLoginToFXRequest = (): Promise<unknown> => {
    return post(`${FX_AUTH_ENDPOINT}/session-activation`, FORCE_LOGIN_BODY);
};

export const forgotPasswordToFXRequest = (email: string): Promise<FxForgotPasswordApiResponse> => {
    return post(`${FX_AUTH_ENDPOINT}/password/forgot`, {
        email,
    });
};

export const activateFXSession = (): Promise<unknown> => {
    return post(`${FX_AUTH_ENDPOINT}/session-activation`, NOT_FORCE_LOGIN_BODY);
};

export const extendFxJwtToken = (chosenOrganizationId: string): Promise<unknown> => {
    return post(`${FX_AUTH_ENDPOINT}/extend-token`, undefined, {
        [ORGANIZATION_HEADER_NAME]: chosenOrganizationId,
    });
};

export const getAccountInfo = (): Promise<FxAccountInfoApiResponse> => {
    return post('/accounts/v1/commands', { type: 'GetAccountsCommand' });
};

export const fetchMeta = (app: ValueOf<typeof URL_APP_TYPE>): Promise<MetaApiResponse> => {
    return post(META_ENDPOINT, {
        type: 'GetWhiteLabelSettingsCommand',
        payload: {
            app,
        },
    });
};

export const checkIsLoggedIn = (): Promise<unknown> => {
    return callApi(`${FX_AUTH_ENDPOINT}/session`);
};
