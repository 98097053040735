import { validatePasswordComparisonI18n } from 'utils';
import i18next from 'i18next';

import { fieldNames, NewPasswordFormErrors } from '../../components/NewPassword';
import { NewPasswordFormData } from '../../types/NewPassword';

const PASSWORD_MIN_LENGTH = 8;

const passwordErrors = ['length', 'upperCase', 'lowerCase', 'digit', 'symbol'] as const;

export type PasswordErrors = (typeof passwordErrors)[number];
export type PasswordError = [string, boolean];

const passwordValidators: Record<PasswordErrors, (value: string) => boolean> = {
    length: (value: string) => value.length >= PASSWORD_MIN_LENGTH,
    upperCase: (value: string) => /\p{Lu}/u.test(value),
    lowerCase: (value: string) => /\p{Ll}/u.test(value),
    digit: (value: string) => /\d/.test(value),
    symbol: (value: string) => /[-!@#$%^&_*()?£]/.test(value),
} as const;

export const passwordValidator = (password: string): Array<PasswordError> => {
    const passwordErrorsDescriptions: Record<PasswordErrors, string> = {
        length: i18next.t('At least 8 characters long'),
        upperCase: i18next.t('At least 1 uppercase letter'),
        lowerCase: i18next.t('At least 1 lowercase letter'),
        digit: i18next.t('At least 1 digit'),
        symbol: `${i18next.t('At least 1 special character from')}\n! ? @ # $ % ^ & * ( ) _ - £`,
    } as const;

    return passwordErrors.map(i => [passwordErrorsDescriptions[i], passwordValidators[i](password)]);
};

export const isPasswordCorrect = (errors: PasswordError[]) => errors.every(([, i]) => i);

export const isFormValid = (errors: NewPasswordFormErrors): boolean =>
    !errors[fieldNames.confirmPassword] && isPasswordCorrect(errors[fieldNames.newPassword]);

interface ValidationResult {
    errors: NewPasswordFormErrors;
    isValid: boolean;
}

export const validateNewPasswordForm = (data: NewPasswordFormData): ValidationResult => {
    const newPassword = data[fieldNames.newPassword];

    const errors = {
        [fieldNames.newPassword]: passwordValidator(newPassword),
        [fieldNames.confirmPassword]: validatePasswordComparisonI18n(newPassword, data[fieldNames.confirmPassword]),
    };

    const isValid = isFormValid(errors);

    return { errors, isValid };
};
