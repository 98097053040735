import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { REQUEST_STATUSES } from 'consts';
import { collectError } from 'utils';

import { createDefaultMeta, getAppType } from '../../utils';
import { fetchMeta } from '../../../../api/fx';
import { metaContext, MetaState } from '../../constants';

const withMeta =
    (Component: React.FC): React.FC =>
    () => {
        const [meta, setMeta] = useState<MetaState>(createDefaultMeta(getAppType()));

        const updateMeta = useCallback((appType: MetaState['appType']) => {
            setMeta(state => ({ ...state, appType }));
        }, []);

        const providerValue = useMemo(() => ({ data: meta, updateMeta }), [updateMeta, meta]);

        useEffect(() => {
            (async () => {
                try {
                    setMeta(state => ({ ...state, status: REQUEST_STATUSES.PENDING }));
                    const response = await fetchMeta(meta.appType);
                    setMeta(state => ({ ...state, meta: response.payload, status: REQUEST_STATUSES.SUCCESS }));
                } catch (error: any) {
                    setMeta({ ...createDefaultMeta(getAppType())(), status: REQUEST_STATUSES.ERROR });
                    collectError(error);
                }
            })();
            // eslint-disable-next-line
        }, []);

        return (
            <metaContext.Provider value={providerValue}>
                <Component />
            </metaContext.Provider>
        );
    };

export default withMeta;
