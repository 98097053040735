import React from 'react';

import PasswordVerifySuccessScreen from '../../components/PasswordVerifySuccessScreen';
import router, { FormRoutes } from '../../../../common/utils/routing';

const PasswordVerifySuccessScreenContainer = () => {
    const onClick: React.MouseEventHandler<HTMLButtonElement> = () => {
        router.go(FormRoutes.login);
    };

    return <PasswordVerifySuccessScreen onClick={onClick} />;
};

export default PasswordVerifySuccessScreenContainer;
