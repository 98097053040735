import React, { useContext } from 'react';

import AppLogoView from '../../components/AppLogoView';
import LoginForm from '../../../loginForm';
import BrowserMessageView from '../../components/BrowserMessageView';
import SupportView from '../../components/SupportView';
import { metaContext } from '../../../meta/constants';
import { getLogoSrc } from '../../utils';
import DigicertView from '../../components/DigicertView';

import styles from './styles.module.scss';

const LoginSide = () => {
    const {
        data: { meta, appType },
    } = useContext(metaContext);
    const logoHref = meta?.logoLink || 'https://courierexchange.co.uk';

    return (
        <div className={styles.login__container}>
            <div className={styles.login__content}>
                <div className={styles.logo__wrap}>
                    <AppLogoView href={logoHref} logo={getLogoSrc(appType)} />
                </div>
                <div className={styles.loginFormWrapper}>
                    <LoginForm />
                </div>
            </div>
            {!!meta && <SupportView data={meta} />}
            <DigicertView spacing={!meta}>
                <BrowserMessageView />
            </DigicertView>
        </div>
    );
};

export default LoginSide;
