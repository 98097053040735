import React, { PropsWithChildren } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface LinkButtonProps extends React.LinkHTMLAttributes<any> {
    className?: string;
    component?: any;
    disabled?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    target?: string;
}

const LinkButton: React.FC<PropsWithChildren<LinkButtonProps>> = ({
    children,
    onClick,
    className,
    component: Component = 'a',
    ...rest
}) => {
    return (
        <Component className={cn(styles.link, className)} onClick={onClick} {...rest}>
            {children}
        </Component>
    );
};

export default LinkButton;
