import React from 'react';
import { useTranslation } from 'react-i18next';
import { validateEmailI18n, validateRequiredI18n } from 'utils';
import { FormikValidationResult } from 'types';

import { hasErrors } from '../../../../common/utils/errors';
import { validateCompose } from '../../../../common/utils/validation';
import BackToLogin from '../BackToLogin';
import FormError from '../FormError';
import Input from '../Input';
import styles from './styles.module.scss';
import SubmitButton from '../SubmitButton';

interface ForgotPasswordFormProps {
    errors: Record<string, FormikValidationResult>;
    formError?: string;
    isSubmitting: boolean;
    onSubmit(event: any): Promise<void>;
    passwordHadBeenSent: boolean;
    setErrors: (errors: ForgotPasswordFormProps['errors']) => void;
}

export const fieldNames = { login: 'login' };

export const validate = validateCompose(validateRequiredI18n, validateEmailI18n);

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
    errors,
    formError,
    isSubmitting,
    onSubmit,
    passwordHadBeenSent,
    setErrors,
}) => {
    const { t } = useTranslation();
    const isError = !!formError || hasErrors(errors);

    if (passwordHadBeenSent) {
        return (
            <>
                <p test-id='password-forgot-remind-password-message'>{t('passwordHadBeenSent')}</p>
                <BackToLogin secondary />
            </>
        );
    }

    return (
        <form onSubmit={onSubmit} test-id='password-forgot-form'>
            {isError && <FormError test-id='password-forgot-form-error'>{formError || errors.email}</FormError>}
            <div className={styles.forgotPasswordText}>
                <span>{t('Forgot Password')}</span>
            </div>
            <BackToLogin right />
            <Input
                setError={setErrors}
                validate={validate}
                error={isError}
                name={fieldNames.login}
                label={t('Email')}
                test-id='password-forgot-field-email'
                autoFocus
                placeholder={t('Enter your email')}
            />
            <SubmitButton progress={isSubmitting} test-id='password-forgot-submit' da-id='password-forgot-submit'>
                {t('Submit')}
            </SubmitButton>
        </form>
    );
};

export default ForgotPasswordForm;
