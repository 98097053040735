import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Organisation } from '../../../../api/types';
import { RadioOption } from '../../types/RadioOption';
import FormError from '../FormError';
import RadioGroup from '../RadioGroup';
import SubmitButton from '../SubmitButton';

interface MemberOrganisationsFormProps {
    options: Organisation[];
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    formError: string | null;
    isLoading: boolean;
}

const MemberOrganisationsForm: React.FC<MemberOrganisationsFormProps> = ({
    options,
    onSubmit,
    formError,
    isLoading,
}) => {
    const optionsToDisplay: RadioOption[] = useMemo(
        () =>
            options.map(({ organisationId, displayName }) => ({
                label: displayName,
                value: organisationId,
            })),
        [options.length],
    );

    const { t } = useTranslation();

    return (
        <form onSubmit={onSubmit} test-id='member-organisations-form'>
            <div>
                <span>{t('Choose an account to Log In')}</span>
            </div>
            {formError && <FormError test-id='member-organisations-form-error'>{formError}</FormError>}
            <RadioGroup
                options={optionsToDisplay}
                name='organisationId'
                test-id='member-organisations-radio-'
                defaultValue={options[0].organisationId}
            />
            <SubmitButton progress={isLoading} test-id='member-organisations-submit'>
                {t('Continue')}
            </SubmitButton>
        </form>
    );
};

export default MemberOrganisationsForm;
