import React from 'react';
import { useTranslation } from 'react-i18next';

import FormError from '../FormError';
import styles from './styles.module.scss';
import SubmitButton from '../SubmitButton';

interface PasswordVerifyProps {
    error?: string;
    loading: boolean;
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
}

const PasswordVerify: React.FC<PasswordVerifyProps> = ({ loading, onClick, error }) => {
    const { t } = useTranslation();

    return error ? (
        <FormError test-id='password-verify-error' da-id='password-verify-error'>
            {error}
        </FormError>
    ) : (
        <div>
            <p className={styles.successTitle}>{t('passwordVerifyHeading')}</p>
            <p className={styles.row}>{t('passwordVerifyMessage')}</p>
            <SubmitButton da-id='password-verify' onClick={onClick} progress={loading} test-id='password-verify'>
                {t('passwordVerifySubmitLabel')}
            </SubmitButton>
        </div>
    );
};

export default PasswordVerify;
